.container {
  display: flex;
  flex-direction: column;
  width: 95%;
  height: auto;
  margin: 0 auto;
  padding: 10px;
}

.section {
  width: 95%;
  height: auto;
  margin: 0 auto;
  padding: 10px;
}

.tableDiv {
  width: 95%;
  height: auto;
  margin: 0 auto;
  padding: 10px;
}
