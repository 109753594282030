.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-color: white;
  border-width: 1px;
  border-radius: 5px;
  border-style: solid;
  margin-top: 8px;
}

.image {
  flex: 1;
  margin: auto;
  align-items: center;
}

.description {
  flex: 4;
  margin: auto;
  padding-left: 20px;
}
