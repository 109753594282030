.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: flex-start;
  //justify-content: space-between;
  justify-content: flex-start;
  padding: 1em 0;
}

.itemContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: start;
  justify-content: flex-start;
  background-color: white;
  color: black;
}

.itemDetails {
  width: 200%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1em 0;
}

.imageDiv {
  position: relative;
}

.leftSide {
  width: 50%;
  flex-grow: 0;
  flex-shrink: 1;
  align-self: self-start;
}

.rightSide {
  width: 50%;
  flex-grow: 5;
  flex-shrink: 0;
  align-self: self-start;
  margin: 0px;
  padding: 2px
}

.detailLeft {
  width: 15%;
  flex-grow: 0;
  flex-shrink: 0;
}

.detailRight {
  flex-grow: 8;
  flex-shrink: 0;
}


.overallContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-items: flex-start ;
  justify-content: flex-start;
  padding: 1em 0;
}
