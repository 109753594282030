.container {
    width: 100%;
    display: flex;
    bgcolor: #ffffff;
}

.modalContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 50%;
    height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    bgcolor: #ffffff;
    border: 2px solid #000;
    padding: 4px;
}

.header {
    flex: 1;
}

.detail {
    flex: 6;
    overflow: auto;
    width: 100%;
}

.footer {
    flex: 1;
}
