.descriptionArea {
    margin: 1.5em 0em;
    background: white;
    padding: 1.5em;
    border-radius: 10px;
  }

.resultsArea {
  margin: 1.5em 0em;
  background: white;
  border-radius: 10px;
}

.headerSection {
    background: #36388D;
    border-radius: 10px;
    color: white;
    padding: 1em;
    margin-bottom: 1em;
  }

.scenarioStepSection {
    width: 100%;
    padding-top: 1em;
}

.itemList {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
  }

.buttonContainer {
    margin-top: 1em;
    text-align: center;
  }

.noDataContainer {
  height: 200px;
  width: 400px;
  border-radius: 20px;
  border-color: darkgreen;
  color: darkgray;
  padding: 1em;
  margin-bottom: 1em;
  background-color: #d2e7d2ff;

}
