.app {
  height: 100vh;
  background: #E5E5E5;
}

.logoHeaderSection {
  background-color: white;
}

.nameHeaderSection {
  background: linear-gradient(#4e7707, #E5E5E5);
}

.mainSection {
  background-color: #E5E5E5;
}

.disclaimerSection {
  text-align: center;
  margin-top: 10px;
}
