.div_button {
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
  border-width: 0;
  background-color: #d0d0d0;
  min-height: 120px;
  padding: 20px;
}

.button_area {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  gap: 10px;
}

.table_area {
  height: 180px;
  width: 100%;
}

.text_area {
  margin-top: 20px;
  width: 100%;
}
