.siteSelectionSection {
  max-height: 380px;
  overflow: auto;
}

// once the screen gets too small
// we want to ensure elements (e.g. google map)
// are hidden from view, because they look ugly
// in such a small area
@media (max-width: 767px) {
  .hiddenMobile {
    display: none;
  }
}

.pickerRow {
  display: flex;
  flex-direction: row;

}

.pickerContainer {
  min-height: 400px;
}

.pickerState {
  flex: 1;
}

.pickerSites {
  flex: 2;
}

.pickerMap {
  flex: 5;
}
