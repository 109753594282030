.container {
  //display: flex;
  //flex: fit-content;
  width: 100%;
  margin: auto;
  min-width: 0;
}

.compareContainer {
  width: 100%;
  margin: auto;
  min-width: 0;
  min-height: 300px;
}

.trimText {
  width: 210px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cellEllipsis {
  display: inline-block;
}
