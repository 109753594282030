.siteSelectionSection {
    max-height: 380px;
    overflow: auto;
}

// once the screen gets too small
// we want to ensure elements (e.g. google map)
// are hidden from view, because they look ugly
// in such a small area
@media (max-width: 767px) {
    .hiddenMobile {
      display: none;
    }
}