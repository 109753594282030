.descriptionArea {
  margin: 1.5em 0em;
  background: white;
  padding: 1.5em;
  border-radius: 10px;
}

.resultsArea {
  margin: 1.5em 0em;
  background: white;
  border-radius: 10px;
}

.headerSection {
  background: #36388D;
  border-radius: 10px;
  color: white;
  padding: 1em;
  margin-bottom: 1em;
}

.scenarioStepSection {
  width: 100%;
  min-height: 400px;
  padding-top: 1em;
}

.itemList {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.buttonContainer {
  margin-top: 1em;
  text-align: center;
}

.flex2columns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 1em;
}

.flex {
  flex: auto;
}

// Styles for Chart Components
.graphContainer {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

}

.graphContainer95 {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 95%;
}

.graphPadded {
  padding-left: 20px;
  padding-right: 20px;
}

.chart {
  flex: 5;
  padding: 2px;
}

.chartExplanation {
  flex: 1;
  padding-top: 5px;
}

.footerContainer {
  padding: 4px;
}

.gaugesBar {
  display: flex;
  flex-direction: row;
  margin-top: 1em;
  justify-content: space-evenly;
  align-items: center;
  row-gap: 0px;
  column-gap: 0px;
  background-color: white;
}

.gaugeContainer {
  width: 100%;
  //height: 200px;
  //width: 250px;
}

.gaugeTitle {
  font-size: 1.2em;
  font-weight: bold;
}

.plantingTitle {
  font-size: 1.2em;
  font-weight: bold;
  align-self: center;
  background-color: white;
  width: 100%;
  text-align: center;
}

.plantingContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
}
