.overallContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    align-items: center;
    text-align: right;
}

.container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.logo {
    margin-left: 5px;
    margin-right: 5px;
    max-height: 75px;
}

@media all and (max-width: 600px) {
    .overallContainer {
        text-align: center;
    }
}

.govLogo {
    height: auto;
    grid-area: 1 / 1 / 2 / 2;
    max-height: 75px;
}

.usqLogo {
    height: auto;
    grid-area: 1 / 1 / 2 / 3;
    max-height: 75px;
}
