.container {
  width: 100%;
  display: flex;
  bgcolor: #ffffff;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 50%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  bgcolor: #ffffff;
  border: 2px solid #000;
  padding: 4px;
}

.buttonBase {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid #a0a0a0;
  border-radius: 1px;
  // padding: 4px;
  // margin: 4px;
}

.buttonHeading {
 padding: 2px;
  font-size: .8em;
}

.button {

}

.header {
  flex: 1;
}

.detail {
  flex: 6;
  overflow: auto;
  width: 100%;
}

.footer {
  flex: 1;
}
