.circleText {
  text-align: center;
  height: 50%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.hover {
  background-color: #0078d4;
}

.markerStyle {
  position: absolute;
  width: 20px;
  height: 20px;
  left: -10;
  top: -10;
};
