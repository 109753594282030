.container {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.details {
    margin-bottom: 20px;
}

.detailsBlue {
    color: #3838a2;
    font-weight: 600;
}

.yields {

}
.yieldError {
    color: red;
}

.descError {
    color: red;
}

.inputs {
    margin-top: 10px;
    padding: 3px;
    width: 50%;
}

.cropLabel {
    margin-right: 20px;
}

.cropType {

}

.cropSeason {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
}

.button {
    margin-left: 10px;
}
