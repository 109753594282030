.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: nowrap;
  align-items: baseline;
}

.label {
  width: 22%;
}

.content {
  flex-flow: row wrap;
  flex-grow: 4;
  justify-content: flex-start;
}

