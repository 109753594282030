.columns5050 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
  margin-top: 10px;
}

.columnHalfFlex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
}

.column-half1 {
  flex: 1 0 auto;
  height: 100%;
}

.column-half2 {
  flex: 6 0 auto;
  height: 100%;
  min-width: 120px;
  margin-left: 10px;
  padding-left: 10px;
  position: relative;
}

//.column-half2 {
//  height: 100%;
//  flex: 1 0 auto;
//  min-width: 240px;
//}

.button-parent {
  position: relative;
}

.bottom-left {
  position: absolute;
  bottom: 0px;
  left: 10px;
}

.exploreContainer {
  margin: 0px;
  padding: 5px;
  height: fit-content;
}

.stepHeading {
  font-weight: 900;
  text-decoration: underline;
  margin-bottom: 10px;
}

.bottomleft {
  position: absolute;
  bottom: 0;
  left: 10%;
}

.tableContainer {
  display: flex;
  margin: 0px;
  padding: 0px;
  height: fit-content;
}

.chartContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 5px;
}
