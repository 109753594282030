.overallContainer {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    align-items: center;
    //justify-content: space-between;
    justify-content: flex-start;
    padding: 1em 0;
    align-items: flex-start;

}

@media all and (max-width: 600px) {
    .overallContainer {
        justify-content: center;
        text-align: center;
    }

    .appSubtitle {
        text-align: center;

    }
}

.nameSection {
    height: auto;
}

.nameSpacer {
    flex: auto 8 0;
}

.logoSection {
    text-align: center;
    align-self: flex-end;
    justify-self: self-end;
}

.appTitle {
    color: white;
    font-weight: 900;
    font-style: normal;
    text-shadow: #2e2e2e 0px 4px 5px;
    margin-top: 20px;
    padding-top: 20px;
    margin-left: -20px;
}

.appLogo {
    width: 150px;
    height: auto;
    margin-right: 1px;
    padding: 1px;
}

.appSubtitle {
    color: white;
    text-shadow: #2e2e2e 0px 4px 5px;
    font-style: normal;
    font-weight: 500;
}
