.outer {
  position: relative;
  height: 100%;
  width: 100%;
}

.topRight {
  position: absolute;
  right: -20px;
  top: -2px;
  opacity: .4;
}

.topLeft {
  position: absolute;
  left: 20px;
  top: -2px;
}

.content {
  padding: 5px;
  margin: 5px;
  color: rgb(256, 256, 256, 1);
  border-radius: 10px;
  border-style: solid;
  border-color: #4e7707;
  border-width: 1px;
  background: linear-gradient(135deg, #4e7707, #7f9a7f), rgba(0,0,0,1);
}
