.formBottomRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: baseline;
}

.creationContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.creationLeft {
    align-content: stretch;
}

.creationRight {

}

.instruction {
    margin-top: 10px;
    font-weight: bold;
}

.tdContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
