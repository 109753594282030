.spiderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 5px;
}

.tableContainer {
  display: flex;
  margin: 0px;
  padding: 0px;
  height: fit-content;
}

.chartContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 5px;
}
