
.page {
  background-image: url(../../src/images/traffic_cone.jpeg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 80vh;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.maintenance {
  text-align: center;
  font-size: 4em;
  font-weight: bold;
  color: yellow;
  vertical-align: middle;
  width: 100%;
}

.contact {
  width: 100%;
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
}

.sorry {
  font-size: 2em;
  font-weight: bold;
  color: white;
  margin-top: 20px;
  text-align: center;
}

.container {
  height: 75vh;
  padding: 10px;
  margin-left: 20px;
  margin-right: 20px;
  background-image: url(../../src/images/traffic_cone.jpeg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
