.flexRow {
  display:flex;
  flex-direction: row;
  justify-content: space-around;
  row-gap: 10px;
}

.flexCol {
  display:flex;
  flex-direction: column;
  column-gap: 4px;
}