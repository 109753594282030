.lowerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px;
  padding: 2px;
}

.middleContainer {
  padding: 8px;
}

.stepContainer {
  margin-bottom: 8px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.leftAlignContents {
  margin: 0 auto;
  width: 50%;
}

.selectCrops {
  margin-top: 20px;
  width: 50%;
}

.croplistContainer {
  margin-top: 10px;
}

.selectRotations {
  margin-top: 20px;
}

.rotationlistContainer {
  margin-top: 10px;
}


.nitrogenContainer {
  width: 100%;
  margin-top: 10px;
}

.selectNitrogen {
  margin-top: 20px;
  width: 100%;
}

.soilprofileContainer {
  margin-top: 10px;
}
.shadedTitle {
  background-color: #e0e0e0;
  color: #000000;
  text-align: left;
}

.halfWidth {
  width: 50%;
}
