.buttonContainer {
  margin-top: 1em;
  text-align: center;
}

.container {
  width: 95%;
  height: auto;
  margin: 0 auto;
  padding: 10px;
  position: relative;
}

//.descriptionArea {
//  margin: 1.5em 0em;
//  background: white;
//  padding: 1.5em;
//  border-radius: 10px;
//}
.descriptionArea {
  margin: 20px 10px;
  background: white;
  padding: 20px 10px;
  border-radius: 10px;
}

//.resultsArea {
//  margin: 1.5em 0em;
//  background: white;
//  border-radius: 10px;
//}
.resultsArea {
  margin: .5em 0em;
  background: white;
  border-radius: 10px;
}

.headerSection {
  background: #36388D;
  border-radius: 10px;
  color: white;
  padding: 1em;
  margin-bottom: 1em;
}

.scenarioStepSection {
  width: 100%;
  padding-top: 1em;
}

.itemList {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.browserColour {
  color: darkred;
  font-weight: 600;
  font-size: 1.2em;
}
